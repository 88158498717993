.App {
  text-align: center;
}

.gradient {
  /* background-image: linear-gradient(to bottom right, black, #269bbb, purple); */
  /* background-image: radial-gradient(100% 100% at 100% 0, black 0, #6d0aa7 100%); */
  background: rgb(15,15,17);
  background: linear-gradient(171deg, rgba(15,15,17,1) 0%, rgba(19,42,214,1) 79%, rgba(0,212,255,1) 100%);;
}

/* body, html {
  background-color: #4827a5;
  height: 100vh;
  width: 100vh;
} */

/* body {
  overflow: hidden;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
