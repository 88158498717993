body {
  font-family: "Calibri";
  background-color: black;
}

.order-container {
  display: flex;
  flex-wrap: wrap;
  padding: .5em;
  justify-content: space-around;
  margin: 1vw;
}

.order-button {
  background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  justify-content: center;
  padding: 1em;
  line-height: 2em;
  position: relative;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-bottom: 1em;
  letter-spacing: .02em;
}

.order-button-grey {
  background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  justify-content: center;
  padding: 1em;
  line-height: 2em;
  position: relative;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-bottom: 1em;
  letter-spacing: .02em;
  opacity: 0.5;
}

.order-button h1 {
  font-size: 3em;
  font-family: "Calibri";
  line-height: 1.5em;
}

.order-button-grey h1 {
  font-size: 3em;
  font-family: "Calibri";
  line-height: 1.5em;
}

.order-button h2 {
  font-size: 1.5em;
  font-family: "Calibri";
}

.order-button-grey h2 {
  font-size: 1.5em;
  font-family: "Calibri";
}

.order-button p {
  line-height: 2em;
}

.order-button-grey p {
  line-height: 2em;
}

.order-button div {
  padding-top: 1em;
}

.order-button-grey div {
  padding-top: 1em;
}

.order-button h3 {
  font-size: 1.25em;
  font-family: "Calibri";
}

.order-button-grey h3 {
  font-size: 1.25em;
  font-family: "Calibri";
}

.order-note {
  background-color: #161616;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  line-height: 2em;
  width: 100%;
  margin: 0.5em 0em 1.5em;
  letter-spacing: .02em;
  line-height: 1.5em;
  font-size: 20px;
  padding: 1em;
  white-space: break-spaces;
}

.archived {
  background-color: #982929;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  line-height: 2em;
  width: 100%;
  margin: 0.5em 0em;
  letter-spacing: .02em;
  line-height: 1.5em;
  font-size: 20px;
  padding: 1em;
  white-space: break-spaces;
}

.order-tag-button {
  background-color: #161616;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  margin: 0.5em 0em;
  letter-spacing: .02em;
  line-height: 1.5em;
  font-size: 16px;
  padding: 0.5em;
  white-space: break-spaces;
  width: 45%;
  height: 7vh;
}

@media screen and (max-width:768px) {
  .order-tag-button {
    height: 12vh;
  }
}

.order-tag-button:hover {
  transform: translateY(-3px);
}

.order-tag-button:active {
  transform: translateY(3px);
}

.order-note h2 {
  margin-bottom: 0.5em;
}

.order-line-items {
  background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  color: rgb(240, 240, 240);
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  justify-content: center;
  padding: 1em;
  position: relative;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-bottom: 1em;
  line-height: 30px;
}

.order-button img {
  padding-top: 2em;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.order-button-grey img {
  padding-top: 2em;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.button-29-app {
  /* display: inline-block; */
  padding: 1rem;
  width: 20rem;
  height: 4rem;
  font-size: 1.25rem;
  margin-bottom: 1em;
  background-image: radial-gradient(100% 100% at 100% 0, #269bbb 10%, #6d0aa7 100%);
  border: 0;
  border-radius: .75rem;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(240, 240, 240);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  font-family: "Calibri";
}

.button-29 {
  margin-bottom: 2em;
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(240, 240, 240);
  cursor: pointer;
  /* display: inline-flex; */
  justify-content: center;
  line-height: 1.5;
  list-style: none;
  overflow: hidden;
  padding: 1em;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  touch-action: manipulation;
  width: 40vw;
  height: 30vh;
  font-family: "Calibri";
}

.rush {
  color: yellow;
}

.quantity-num {
  font-size: 1.75em;
  color: yellow;
  padding-left: .125em;

}

.quantity {
  margin-bottom: 1.5em;
  margin-top: .5em;
}



.button-29 h1 {
  font-size: 3em;
  /* font-family: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif'; */
  font-family: "Calibri";
}

.button-29 h2 {
  font-size: 1.5em;
  font-family: "Calibri";
}

.button-29 div {
  padding-top: 1em;
}

.button-29 h3 {
  font-size: 1.25em;
  font-family: "Calibri";
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-5px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(5px);
}

.button-29-app:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29-app:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29-app:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(5px);
}

.orders {
  background-color: rgb(77, 157, 184);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 3px 3px 10px rgb(10 10 10 / 60%);
  /* border: groove 1em; */
  border-radius: 10px;
  width: 30em;
  margin-bottom: 2em;
  text-align: left;
}

p {
  font-size: 20px;
  margin-bottom: 2em;
  line-height: 1em;
}

.h1-text {
  font-size: 40px;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  color: rgb(240, 240, 240);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
}

a {
  color: black;
  text-decoration: none;
}

.black {
  color: black;
  border: 4px solid black;
  background-color: rgb(212, 212, 212);
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.white {
  color: white;
  border: 3px solid white;
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.red {
  color: red;
  border: 3px solid red;
  background-color: rgb(235, 181, 181);
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.blue {
  color: blue;
  border: 3px solid blue;
  background-color: lightblue;
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.green {
  color: green;
  border: 3px solid green;
  background-color: lightgreen;
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.yellow {
  color: yellow;
  border: 3px solid yellow;
  background-color: lightyellow;
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.graphite {
  color: rgb(68, 70, 77);
  border: 3px solid rgb(68, 70, 77);
  background-color: rgb(141, 141, 141);
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.copper {
  color: rgb(131, 75, 25);
  border: 3px solid rgb(131, 75, 25);
  background-color: rgb(224, 195, 170);
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.gold {
  color: rgb(229, 198, 105);
  border: 3px solid rgb(229, 198, 105);
  background-color: rgb(78, 63, 18);
  font-size: 22px;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.combs {
  color: white;
  border: 3px solid white;
  font-size: 22px;
  padding: 2px 4px;
  letter-spacing: .03em;
}

.buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  margin: auto;
}

@media (min-width: 1200px) {

  .button-50 {
    padding: 12px 50px;
  }

  .order-button {
    width: 75%;
  }

  .order-button-grey {
    width: 75%;
  }

  .order-line-items {
    width: 75%;
  }

  .order-line-items img {
    width: 30%;

  }
}
