.doubles-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    padding: .5em;
    justify-content: space-around;
    margin: 1vw;
}

.double-div h1 {
    font-size: 2.5em;
    font-family: "Calibri";
    line-height: 1.25em;
}

.double-div {
    display: flex;
    margin-bottom: 2em;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: rgb(240, 240, 240);
    justify-content: center;
    line-height: 1.5;
    list-style: none;
    overflow: hidden;
    padding: 1em;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    touch-action: manipulation;
    width: 90vw;
    font-family: "Calibri";
    letter-spacing: .02em;
    line-height: 2em;
    position: relative;
    text-align: left;
    font-size: 18px;
    margin-bottom: 1em;
}

.double-div img {
    margin: 1em 0em 0em 1em;
    padding: 0.5em;
    justify-content: center;
    width: 100%;
}

.double-div p {
    line-height: 1.5em;
}

.double-div h2 {
    margin-bottom: 0.5em;
    margin-top: 0;
}
