.loading-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 40vh;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 1;
}
