.missing-container {
    display: flex;
    flex-direction: column-reverse;
    margin: 1vw;
}

.missing-div {
    background-image: radial-gradient(100% 100% at 100% 0, #269bbb 0, #6d0aa7 100%);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    color: rgb(240, 240, 240);
    justify-content: center;
    padding: 1em;
    line-height: 2em;
    text-align: left;
    font-size: 18px;
    height: 100%;
    width: 100%;
    margin-bottom: 1em;
    letter-spacing: .02em;
}

.missing-div h1 {
    font-size: 2em;
    font-family: "Calibri";
    line-height: 1.25em;
}

.missing-div p {
    line-height: 2.5em;
}

.missing-div h2 {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-size: 1.5em;
}

.missing-div h3 {
    font-size: 1.25em;
}
